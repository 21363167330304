import React from "react";
import { useSelector } from "react-redux";
import Layout from "../../layouts/ja";
import SEO from "components/seo";
import Promotion from "../../services/Promotion";
import "../../style/promotion.css";
import langData from "../../data/static-pages/pages/promotion/ja";
import PromotionDetail from "../../services/PromotionDetail";
import { IN_PROGRESS } from "state/constants/actions";

const PromotionPage = ({ ...props }) => {
 
  const { uiState: promotionUiState } = useSelector((state) => state.promotion);
  const { uiState: promotionsUiState } = useSelector(
    (state) => state.promotions
  );
  const isUiLoading =
    promotionUiState === IN_PROGRESS || promotionsUiState === IN_PROGRESS;

  const promotions = props.data.cms.rewardsPromotions.data;

  return (
    <Layout location={props.location} footer_margin={isUiLoading}>
      <SEO title="Promotion " />
     
        <Promotion
          langData={langData}
          langKey={props.pageContext.langKey}
          detailUrl={""}
          promotions={promotions}
        />
     
    </Layout>
  );
};

export default PromotionPage;

export const query = graphql`
  query Pormotionsja {
    cms {
      rewardsPromotions(locale: "ja") {
        data {
          attributes {
            slug
            display_end_date
            display_start_date
            start_date
            order
            is_featured
            image {
              data {
                attributes {
                  cdnURL
                }
              }
            }
            rewards_promotion_details {
              data {
                attributes {
                  name
                  intro
                }
              }
            }
          }
        }
      }
    }
  }
`;
